<template>
  <div class="work-page ca-watch-me">

    <div class="container-block">

    	<!-- Header -->
    	<section class="header">
        <img src="/static/images/CAG_1.png"/>
    		<div class="meta" style="margin-top: -10px;">
    			<p class="client">Cricket Australia</p>
    			<a class="project">Watch Me Play</a>
    		</div>
    		<div class="copy container">
    			<div class="col-1">
    				<h1>Encourage girls to play cricket.</h1>
    			</div>
    			<div class="col-2">
    				<p>Girls who participate in sport are more confident in life. Playing a game like cricket allows them to think: If I can do this, what else can I do?</p>
    			</div>
    		</div>
    	</section>

      <div class="spacer-lg"></div>

      <div class="two-col">
        <div class="col" style="width: 42%; margin-right: -10%; z-index: 2;">
          <img src="/static/images/CAG_2.png"/>
        </div>
        <div class="col" style="width: 60%; margin-right: -8%;">
          <img src="/static/images/CAG_3.jpg"/>
        </div>
      </div>

    </div>

    <!-- <div class="spacer-lg"></div> -->

    <div style="padding:56.25% 0 0 0; position:relative;">
      <!-- <div 
      id="poster-image"
      class="poster-image"
      style="background: url('/static/images/CAG_placeholder.png') center center; background-size: cover"
      ></div> -->
      <iframe 
        class="video"
        src="https://player.vimeo.com/video/363716694?title=0&byline=0&portrait=0" 
        style="position:absolute;top:0;left:0;width:100%;height:100%;" 
        frameborder="0" 
        allow="autoplay; fullscreen" 
        allowfullscreen>
      </iframe>
    </div>

    <!-- <div class="spacer-lg"></div> -->

    <div class="container-block">

      <div class="standout-copy" style="text-align: left; width: 100%;">
        We wanted to show cricket in a new and fresh way.
      </div>

      <div class="spacer-lg"></div>

      <img src="/static/images/CAG_4.jpg" style="width: 90%; float: right;"/>

      <div class="clear"></div>

      <div class="spacer-lg"></div>

      <img src="/static/images/CAG_5.png"/>

      <div class="spacer-md"></div>

      <img src="/static/images/CAG_6.jpg" style="width: 60%; float: right; margin-right: 5%"/>

      <div class="clear"></div>

      <img src="/static/images/CAG_7.png" style="width: 40%; margin-left: 5%; position: relative;z-index:3; margin-top: -5%; margin-bottom: -5%;"/>

      <img src="/static/images/CAG_8.jpg"/>

      <div class="footer">
        <div class="prev">
          <router-link to="/nike-women">
            <img src="/static/images/prev.svg"/>
          </router-link>
        </div>
        <div class="footer-contact">
          <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
          <span>&copy; {{date}} by Blood UTD</span>
        </div>
        <div class="next">
          <router-link to="/fitzroy-lions">
            <img src="/static/images/next.svg"/>
          </router-link>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
export default {
  name: 'ca-watch-me-play',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  },
  mounted() {
    // $(".video").hover(function(event) {
    //     if(event.type === "mouseenter") {
    //         $(this).attr("controls", "");
    //     } else if(event.type === "mouseleave") {
    //         $(this).removeAttr("controls");
    //     }
    // });
    // $('.video').click(function(el) {
    //     this.paused ? this.play() : this.pause();
    // });

    // document.getElementById("poster-image").onclick = function() { 
    //     $(".poster-image").hide();
    //     $(".video")[0].src += "&autoplay=1";
    //  };
  }
}
</script>

<style lang="scss" scoped>

.ca-watch-me {

}

</style>
