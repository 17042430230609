<template>
  <div class="work-page nike-play-gold">
  	<!-- Header -->
  	<section class="header">

      <img v-lazy="'/static/images/FL_1.jpg'"/>
    
  		<div class="meta">
  			<p class="client">Fitzroy Lions FC</p>
  			<a class="project">BLD UTD Sponsorship</a>
  		</div>
  		<div class="copy container">
  			<div class="col-1">
  				<h1>Challenging the bias.</h1>
  			</div>
  			<div class="col-2">
  				<p>Australian media often paint African youths in a negative light. Fitzroy Lions SC is working hard to change that. So we decided to sponsor them, to be part of the good fight. </p>
  			</div>
  		</div>
  	</section>

    <div class="spacer-lg"></div>

    <div class="two-col">
      <div class="col" style="width: 64%; margin-left: 0%;">
        <img src="/static/images/FL_2.jpg"/>
      </div>
      <div class="col" style="width: 40%; margin-left: -4%; z-index: 2;">
        <img src="/static/images/FL_3.jpg" style="margin-top: 10%"/>
        <img src="/static/images/FL-text-1.png" style="margin-top: 10%"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/FL_4.jpg" style="width: 80%; float: right"/>

    <div class="spacer-lg" style="clear: both"></div>

    <div class="two-col" style="justify-content: space-between;">
      <div class="col" style="width: 49%;">
        <img src="/static/images/FL_5.jpg" style="width: 80%; margin-top: -120%; margin-right: 10%"/>
      </div>
      <div class="col" style="width: 49%; display: flex; flex-direction: column; justify-content: flex-end; align-items: flex-end; margin-top: 20%;">
        <img style="width: 100%; z-index: 3; position: relative;" src="/static/images/FL_6.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/FL_7.jpg"/>

    <div class="spacer-lg"></div>

    <div class="standout-copy" style="text-align: left; width: 100%;">
      Through sport these kids have become the authors of their own story.  
    </div>

    <div class="spacer-lg"></div>

    <div class="two-col" style="justify-content: space-between; align-items: flex-start; margin-top: 20%">
      <div class="col" style="width: 30%; position: relative; z-index: 2;">
        <img src="/static/images/FL_8.jpg" style="width: 130%; margin-right: -50%; margin-top: -80%;"/>
      </div>
      <div class="col" style="width: 100%;">
        <img src="/static/images/FL_9.jpg"/>
      </div>
    </div>

    <div class="spacer-lg"></div>

    <img src="/static/images/FL-text-2.png" style="margin-bottom: -3.4%; position: relative; z-index: 2; width: 70%"/>

    <img src="/static/images/FL_10.jpg"/>

    <div class="footer">
      <div class="prev">
        <router-link to="/ca-watch-me-play">
          <img src="/static/images/prev.svg"/>
        </router-link>
      </div>
      <div class="footer-contact">
        <a href="mailto:grant@bloodutd.com,david@bloodutd.com">Drop us a line</a>
        <span>&copy; {{date}} by Blood UTD</span>
      </div>
      <div class="next">
        <router-link to="/western-bulldogs">
          <img src="/static/images/next.svg"/>
        </router-link>
      </div>
    </div>

  </div>
</template>

<script>
export default {
  name: 'fitzroy-lions',
  data() {
    return {
      date: new Date().getFullYear(),
    }
  }
}
</script>

<style lang="scss" scoped>
.fitzroy-lions {


}
</style>
